.legenda {
    align-items    : center;
    justify-content: space-between;
    padding        : 1px;
    border         : 1px solid #024135;
    border-radius  : 5px;
    font-size      : 15px;
    margin-bottom  : 20px;
}

.distButton {
    background-color: #078EC8;
    padding         : 15px;
    border          : 1px none;
    border-radius   : 5px;
    color           : white;
    font-size       : 15px;
    margin-bottom   : 20px;

}

.headerPanel {
    display        : flex;
    width          : 100%;
    justify-content: space-between;
    flex-direction : row;
}

.legenda ul {
    list-style     : none;
    display        : flex;
    justify-content: space-around;
    margin         : 0;
    padding        : 10px;
}

.legenda li {
    display    : inline-block;
    margin-left: 15px;
}

.progress {
    width: 50%;
}

.input {
    width: 60px !important;
}

.content {
    display         : flex;
    justify-content : center;
    align-items     : center;
    padding         : 15px;
    background-color: #c9c8c8;
    margin          : auto;
}

.tables {
    width: 98%;
}

.textLote {
    text-align : center;
    align-items: center;
    margin     : 0;
}

.titleLote {
    text-align: center;
    font-size : 1.5em;
}

.input {
    width: 60px !important;
}

.lista {
    width: 100%;
}

.coluna {
    text-align : center;
    align-items: center;
}

.colunaDistribuir {
    align-items    : center;
    display        : flex;
    flex-direction : row;
    flex-wrap      : wrap;
}

.distribuir {
    text-align: center;
    width: 100%;
}
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;