@import '~antd/dist/antd.less';




.ant-select-dropdown {
        background-color: white;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-picker {
    border: 1px solid #d9d9d9;
}


@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;