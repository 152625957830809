
:global {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector  {
     
        border: 1px solid #009696;
        border-radius: .75em;
        box-shadow: none;
        font-weight: 600;
        transition: all .3s;
     
      }
    
  }

@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;