.itemLista {
    margin-left: 35px;
    display    : inline-block;
}

.body {
}

.listaHeader {
    display        : flex;
    list-style     : none;
    flex-direction : row;
    justify-content: space-around;
    margin-left    : -5%;
    margin-bottom  : 0;
}

.infoDesenho {
    margin    : 0px 30px;
    text-align: center;
}

.collapseCriterio {
    margin-top  : 30px !important;
    margin-right: 30px !important;
}

.collapseNota {
    margin-top  : 30px !important;
    margin-right: 30px !important;
}

.collapseSubcriterio {
    padding: 0 !important;
}

.contentSubcriterio {
    display        : flex;
    justify-content: space-between;
    max-width      : 400px;
    margin         : 0 auto;
    padding        : 10px 0;
}

.contentCriterio {
    display        : flex;
    justify-content: space-between;
    max-width      : 400px;
    width          : 100%;
    margin         : 0;
    color          : white;
}

.contentNotaFinal {
    display        : flex;
    justify-content: space-between;
    max-width      : 400px;
    width          : 100%;
    margin         : 0;
}

.inputSubcriterio {
    width: 100px !important;
}

.inputCriterio {
    width: 60px !important;
}

.headerCriterio {
    background-color: #024135;
}

.desenhoPNG {
    margin: 30px 30px 10px;
    width : 70%;
}

.desenhoPDF {
    margin: 30px 30px 10px;
}

.pdf {
    border: 2px solid black;
}

.pdfDesenho {
    border    : 2px solid black;
    max-height: 800px;
}

.diarioDeBordo {
    margin-top : 40px;
    text-align : center;
    align-items: center;
}

.modal {
    height         : 800px;
    display        : flex;
    justify-content: center;
    width          : 100% !important;
}

.btnDiario {
    width     : 20em;
    margin-top: -20px;
}

.desenhoArea {
    display   : flex;
    max-width : 700px;
    max-height: 570px;
    margin    : 30px 30px 10px;
}

.pdf {
    padding: 5px;
}

.pdfDiario {
    padding      : 5px;
    border       : 1px solid black;
    border-radius: 2px;
    margin-top   : 10px;
}

.linhaCriterio {
    margin: 0;
}

.botao {
    background-color: #078EC8;
    color           : white;
    width: 100px;
}

.buttons {
    margin-right: 30px !important;
    margin-top : 30px;
    align-items: center;
    text-align : center;
    justify-content: space-between;
}

.tela{
    padding-bottom: 100px;
}

.desenho{
    text-align: center;
}

.pdfContainer {
    height    : 100%;
    max-height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin    : 0 10px;
    padding   : 0 10px;
}
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;