.rodape {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #004F9F;
    ;
    color: white;
    height: 3em;
    transition: all 0.3s linear;
    display: grid;
    grid-template-columns: auto auto;
    /* cor padrão background: #024135;   */
    /* border-top           : 2em solid #FFFFFF;
    border-radius           : 2em 2em 0em 0em;  */
    z-index: 3;
}

.sgi_logo {
    width: 100px;
    margin-right: -1em;
}

.svg_inline__fa.fa_align_left.fa_w_14 {
    margin-right: 5px;
    margin-bottom: 0px !important;
}

.my_menu:hover {
    -webkit-transform: scale(1.25);
    margin-top: 0em 1.25em 2.50em 0em;
}

.my_menu {
    height: 50px;
    width: 50px;
    transition: all 0.3s linear;
    /* width  : 100%; */
}

.menu_item {
    display: grid;
    grid-template-columns: 17% 83%;
    row-gap: 2em;
    padding-top: 2rem;
}

.left-column {
    overflow: hidden;
}

.menu_aberto {
    height: 30em;
    width: 25em;
    transition: all 0.65s ease-out;
    background-color: #343a4000;
    bottom: 0px;
    position: fixed;
    margin-bottom: -515px;
    overflow-x: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    z-index: 3;
}

.menu_aberto::-webkit-scrollbar-track {
    background: rgb(52, 58, 64);
}

.menu_aberto::-webkit-scrollbar {
    width: 6px;
    background: rgb(52, 58, 64);
}

.menu_aberto::-webkit-scrollbar-thumb {
    background: #0d0d0d;
}

.submenu_child_ul>li>a>img {
    width: 50px;
    height: 50px;
}

.submenu_child_ul>li>a {
    text-decoration: none;
}

.submenu_child_ul>li>a {
    text-decoration: none;
}

.submenu_child_ul>li>a {
    text-emphasis: none;
    color: white;
}

.submenu_child_ul {
    list-style-type: none;
    width: 85%
}

.submenu_child_ul>li {
    padding: 1rem;
    transition: all 0.65s linear;
    box-shadow: 0 3px 2px -2px rgb(255, 255, 255)
}

.submenu_child_ul>li:last-child {
    box-shadow: none;
}

/* .menu-child-ul > li{
    
    
} */

.menuItemLi:hover {
    background-color: rgb(90, 89, 89);
}

.menu_lista {
    padding: 0rem 2rem 2rem 1rem;
    list-style-type: none;
    display: grid;
    grid-template-rows: auto auto auto auto auto auto;
    color: white;
}

// .menu_lista>li  {
//     height: 50px;
//     align-items: center;
//     display: flex;
// }

.menu_lista>li :hover {
    background-color: rgb(90, 89, 89)
}

.menu_lista>li>div>a {
    color: white;
    display: block;
}

.menu_lista>li>div>a>img {
    padding: 2px;
    width: 50px;
    height: 50px;
    filter: invert();
}

.menu_lista>li>div>a>.icon {
    width: 50px;
    height: 30px;
}

.perfil_info {
    list-style-type: none;
    display: grid;
    grid-template-rows: auto auto auto auto auto auto;
    row-gap: 1em;
    color: white;
}

.right_column {
    padding: 0.5rem 0.5rem 1rem 0.5rem;
}

.btn_perfil {
    margin-left: -1.15rem;
    background-color: #004F9F !important;
    border-color: #004F9F;
    display: inline-block;
    font-weight: 600;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.75rem 1.5rem;
    font-size: .875rem;
    line-height: 1.5rem;
    border-radius: 0;
}

.submenu_pefil_child_ul>li {
    transition: all 0.65s linear;
    list-style-type: none;
}

.submenu_perfil {
    margin-left: -5px;
}

.submenu_perfil_hover {
    margin-left: -5px;
    margin-top: 0;
}

li.logoutItem {
    padding-top: 1rem;
}

.link_menu {
    display: block;
    height: 30px;
}

.alinhar_centro {
    display: flex;
    align-items: center;
    height: 50px;
}

.logos_grid {
    display: flex;
    margin-right: 10px;
    align-items: center;
    justify-content: flex-end;
    justify-items: flex-end;
}

.sgi_logo>img {
    width: 70px;
}

.governo_logo {}

.governo_logo>img {
    width: 90px;
}

.btn_menu {
    height: 52px;
    align-items: center;
    margin: auto;
    padding-left: 1em;
    padding-right: 1em;
    background-color: rgba(255, 255, 255, 0) !important;
    border: none !important;
    vertical-align: baseline !important;
    font-size: 15px;
}

.btn_menu:hover {
    background-color: #013d79 !important;
    border: none !important;
    vertical-align: baseline !important;
}

.menu_btn {
    display: inline-flex;
    height: 52px;
}

.icon_menu>svg {
    margin-right: 8px;
}

.nome_usuario {
    padding-top: 0 !important;
}

.danger {
    color: rgb(255, 93, 93) !important;
}
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;