.UploadName span>span:first-of-type {
    width: 20vw !important;
}

.situacaoTableDesenho {   
    border-radius: 13px;
    padding: 0 10px;
    color:white;
    margin: 0;

}

.colunaDesenhoTabelaDesenho {
    width: 35px !important;
    height: 35px !important;
    cursor: pointer;
}

.Aprovado {
    background-color: green;
}

.Desclassificado {
    background-color: red;
    border: 1px solid rgb(160, 160, 160);
}

.Desclassificado:hover{
    cursor: pointer;
    color: rgb(160, 160, 160);
    background-color: rgb(255, 64, 64);
}

.ASelecionar {
    background-color:rgb(185, 178, 62);
}
.Situacao {
    background-color: gray;
}

.buttonAbrir {
    min-width: 100px;
    max-width: 300px;
    width: 100%;
    height: 45px !important;
    border: 1px solid #07a789 !important;
    background-color: rgb(255, 255, 255);
    color: rgb(3, 142, 191, 80%);
    font-size: 13px;
    font-weight: bold;
    text-overflow: ellipsis;
    padding: 8px;
    cursor: pointer;
}

.buttonAbrir:hover {
    border: 1px solid rgba(3, 179, 243, 0.8);
    background-color: rgb(3, 142, 191, 80%);
    color: rgb(255, 255, 255);
}

.buttonAberto {
    min-width: 100px;
    max-width: 300px;
    width: 100%;
    height: 45px !important;
    border: 1px solid #07a789 !important;
    background-color:#c9c8c8 !important;
    color: rgb(83, 83, 83) !important;
    font-size: 13px;
    font-weight: bold;
    text-overflow: ellipsis;
    padding: 8px;
    cursor: pointer;
}