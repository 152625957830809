.react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {}

.react-pdf__Page__canvas {
    margin: 0 auto;
}

::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}

::-webkit-scrollbar {
    width: 6px;
    background: #F4F4F4;
}

::-webkit-scrollbar-thumb {
    background: #9b9b9b;
}


