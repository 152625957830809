.status{
    display: flex;
    width: 100%;
    margin: 20px 0;
 }

.lista{
    display: flex;
    list-style:none;
    flex-direction:row;
    margin-left:-5%;
    margin-bottom: 0;
    margin-top: 10px;
}

.listaHeader{
    display: flex; 
    list-style: none; 
    flex-direction: row; 
    justify-content: space-around; 
    margin-left: -5%;
    margin-bottom: 0;
}

.itemLista{
    margin-left: 35px;
    display: inline-block;
}

.Aprovado{
    color:green;
    
}

.Desclassificado{
    color:red;
}

.ASelecionar{
    color: rgb(185, 178, 62);
}

@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;