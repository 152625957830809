.Titulo {
    font-size: 2em;
    font-weight: bold;
    color: #009696;
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
}

.Centro {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    margin: auto;
    margin-top: 100px;
    font-size: 1.2em;
    text-align: center;
}
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;