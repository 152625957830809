.situacaoTableDesenho {   
    border-radius: 13px;
    padding: 0 10px;
    color:white;
    margin: 0;

}
.Avaliar {
    background-color: gray;
}

.EmAvaliacao {
    background-color: rgb(185, 178, 62);
}
.Avaliado {
    background-color: green;
}

.headerPanel{
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
}

.legenda ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin:0;
}

.legenda li{
    display: inline-block;
}

.legenda{
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #024135;
    border-radius: 5px;
    font-size: 15px;
    margin-bottom: 20px;
}