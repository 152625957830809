@media (max-height: 715px) {
  /* Chrome 29+*/
  @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
      html {
          zoom: 0.9;            
      }
  }

  /* Chrome 22-28 */
  @media screen and(-webkit-min-device-pixel-ratio:0) {
      html {
          zoom: 0.9;   
      }
  }

  /*Firefox*/
  @-moz-document url-prefix() {
      .conteudo {       
          -moz-transform: scale(0.9);                       
          margin-top: -40px;
      }
  }

}


 
.ant-table-var-local::-webkit-scrollbar {    
    width: 0px; 
    
    background:transparent;        
    }
    


.ant-table-sticky-scroll-bar {
    height: 15px !important;
    margin-bottom: 130px ;        
}