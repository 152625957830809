.container{
    max-width: 1300px;
    min-width: 360px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.fieldset_consulta{
    border: 1px solid #888;
    padding: 2rem 2rem 1rem 2rem;    
}

.button_filtrar {
    margin-left: 8px;
    float: right;
}
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;