.fieldset_consulta {
    border: 1px solid #888;
    padding: 2rem 2rem 1rem 2rem;
    margin-top: 2em;
    border-radius: 7px;
}

// .buttons {
//     display: inline-block;
// }

table,
td {
    border-right: none !important;
}

th {
    font-weight: 200 !important;
    background-color: #004F9F !important;
}

.status {
    display: flex;
    width: 100%;
}

:global {
    .ant-table-small .ant-table-thead>tr>th {
        color: white;
        border: none !important;
    }

    .ant-table.ant-table-bordered>.ant-table-footer {
        background: #004F9F ;
        border-radius: 0px 0px 10px 10px;
        height: 50px;
        display: flex;
    }

    .ant-table.ant-table-bordered>.ant-table-footer>tr {
        display: flex;
        align-items: center;
    }

    .ant-table.ant-table-bordered>.ant-table-footer>tr>span {
        color: white;
        font-weight: 200;
        margin-left: 20px;
    }

    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>.ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>.ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>.ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>.ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>.ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>.ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>.ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>.ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>.ant-table-cell-fix-right-first::after,
    .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>.ant-table-cell-fix-right-first::after {
        border: none !important;
    }
}
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;