.borderClassificado {   
    border-radius: 13px;
    padding: 0 10px;
    color:white;
    margin: 0;
    background-color:green;

}
.Avaliar {
    background-color: gray;
}

.EmAvaliacao {
    background-color: rgb(185, 178, 62);
}
.Avaliado {
    background-color: green;
}

.headerPanel{
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
}

.legenda ul {
    list-style: none;
    display: flex;
    justify-content: space-around;
    margin:0;
}

.legenda li{
    display: inline-block;
}

.legenda{
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #024135;
    border-radius: 5px;
    font-size: 15px;
    margin-bottom: 20px;
}

.status{
    display: flex;
    width: 100%;
    margin: 20px 0;
 }
 .lista{
    display: flex;
    list-style:none;
    flex-direction:row;
    margin-left:-5%;
    margin-bottom: 0;
    margin-top: 10px;
}

.listaHeader{
    display: flex; 
    list-style: none; 
    flex-direction: row; 
    justify-content: space-around; 
    margin-left: -5%;
    margin-bottom: 0;
}

.itemLista{
    margin-left: 35px;
    display: inline-block;
}


  
.tableRowClasificadoPopularPorNota td {
    background: #3dfb96
  }

  .tableRowClasificadoPopularPorComissao td {
    background: #b9aeff        
  }
.ClassificadoPopularPorNota {
    color:#00ff77
}

.ClassificadoPopularPorComissao {
    color:#7a67f5
}