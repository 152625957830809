
span{
font-weight: bolder;  
}

.fieldsetConsulta{
border: 1px solid #888;
padding: 2rem 2rem 1rem 2rem;    
}


.logo{
width: inherit;
display: grid;
grid-template-columns: 25% 50% 25% ;
align-content: center;  
justify-items: center;
}

img#logo-img{
width: 20%;
}


    .table-striped tr:hover {
    background:#929492bd;
    }

    .input_fields_wrap{
    display: flex;
    padding: 1rem;
    }
    
    .container, .container-lg, .container-md, .container-sm, .container-xl{
    padding-bottom: 5rem !important;
    }
    
    
    @media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl{
      max-width: 1250px !important;
    }
    
    }            
    
    
    
    .fc .fc-daygrid-body{
    z-index: 0 !important;
  
    }
    /* Por questões hierárquicas
    estilos para sobre escrever componentes prontos
    precisam ser feitos aqui no css geral do sistema
    seguido da clausua !important */
    /* ajuste nas abas do cadastro */
    
    .ant-tabs-content-holder{
    margin-bottom: -4.75rem !important;
    }
    
    #rc-tabs-0-panel-4{
    margin-bottom: 5rem !important;
    }

    
    // .ant-table-sticky-scroll-bar { margin-bottom: 9em !important;      
    // cursor:pointer;
    // }
    
    
    
    body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }     
    
    
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;