@use "theme";
@import url("https://fonts.googleapis.com/css2?family=Mulish&family=Raleway&display=swap");

@font-face {
  font-family: "MavenPro";
  src: url(MavenPro-VariableFont_wght.ttf);
}

* {
  font-family: MavenPro;
}


.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1250px!important;
}

.container, .container-lg, .container-md, .container-sm, .container-xl{
  padding-bottom: 5rem !important;
}


@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl{
        max-width: 1250px !important;
    }
}