.container {
    max-width: 1300px;
    min-width: 360px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.AreaBanner {
    display: grid;
    height: 87vh;
    justify-content: space-around;
    width: 100%;
    align-items: center;
}

.buttonDownload {
    min-width: 100px;
    max-width: 300px;
    width: 100%;
    height: 40px;
    border: 1px solid rgb(3, 142, 191, 80%);
    background-color: rgb(255, 255, 255);
    color: rgb(3, 142, 191, 80%);
    font-size: 13px;
    font-weight: bold;
    text-overflow: ellipsis;
    padding: 8px;
    cursor: pointer;
}

.buttonDownload:hover {
    border: 1px solid rgba(3, 179, 243, 0.8);
    background-color: rgb(3, 142, 191, 80%);
    color: rgb(255, 255, 255);
}

.manuaisUso {
    text-align: center;
}