.Buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: bold;
    align-items: center;
    margin-bottom: 1%;
}

.button{
    text-align: center;
}
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;