.logosGrid {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    justify-items: flex-end;
    padding-right: 1%;
}

@media only screen and (max-width:588px) {
    .logosGrid {
        display: none;
    }
}

.footer {
    background-color: #004F9F;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    height: 3em;
    transition: all 0.3s linear;
    display: flex;
    z-index: 3;
    justify-content: space-between;
    padding: 0 20px;
}

.imgs {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin-right: 15px;
    padding: 10px;
    align-items: center;
}

.img3 {
    width: 80px;
    margin-left: 15px;
}

@media (max-width: 700px) {
    .footer {
        position: fixed;
        z-index: 100;
    }
}

.img {
    width: 50px;
}

.setdig {
    width: 80%;
}