@verde_1: #77ae4a;
// @verde_1: #77ae4a;
@azul_1: #004F9F;
// @azul_1: #009696;

html, body {
    font-family: MavenPro;
    color: @azul_1;
    font-size: 1.05em !important;
    /* background-image: url(../Content/images/bg_portal.png); */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@font-face {
    font-family: "MavenPro";    
    src: url(../../../assets/MavenPro-VariableFont_wght.ttf); 
}

.logo_img {
    max-width: 60%;
}

.btn_container {
    width: 50%
}

.confirm_button {
    background-color: @azul_1 !important;
    color: white !important;
    font-family: MavenPro !important;
    font-weight: 400 !important;
    border: none;
}

.warming_button {
    border: 1px solid #ff4e00;
    background-color: white;
    color: #ff4e00; 
    background: linear-gradient(to right, #ff4e00 50%, white 50%);
    background-size: 205% 110%;
    background-position: right bottom;
}

    .warming_button:hover {
        background-position: left bottom;
        background-color: #ff4e00;
        color: white;
    }
.login_btn {
    border-radius: 5px !important;
    text-align: center;
    transition: all 0.75s;
    cursor: pointer;
    width: 50%;
    height: 3.5em !important;
    margin: 5px;
}

.grid_container {
    display: flex;
    align-items: center;
}

/*--------------------//-------------------------*/

* {
    box-sizing: border-box;
}

html, body, .wrapper, .sct {
    height: 100%;
    margin: 0;
}

a {
    text-decoration: none;
    color: rgb(22, 185, 185);
}

h1 {
    font-size: 6rem;
}

h2 {
    font-size: 3rem;
}

h3 {
    font-size: 1.5rem;
}

input:active, input:focus, input:visited, input:hover {
    outline: none;
}

.input_container {
    padding: 0.85em;
}

.formulario {
    text-align: center;
    border: 1px solid @azul_1;
    border-radius: 1em;
    background-color: #fff;
    padding: 30px !important;
}

.titulo {
    font-family: 'MavenPro' !important;
    font-size: 1.5em;
    font-weight: bold;
    color:   @azul_1;
}

.cad {
    color: @azul_1;
}

.mi {
    color: #D66C03;
    margin-left: -5px;
}

.ms {
    color: @verde_1;
    margin-left: -5px;
}

.sisTitulo {
    margin-top: -1rem;
    display: none;
}

.ant-form-item {
    margin-bottom: 20px !important;
}

.input {
    width: 100% !important;
    margin-bottom: 0.5rem !important;
    padding: 0.75em 0.75em !important;
    background: #fff !important;
    border: 1px solid @azul_1 !important;
    font-weight: 600;
    font-family: MavenPro !important;    
    color:  @azul_1 !important ;
    font-size: 1em !important;
    -moz-border-radius: 0.75em !important;
    -webkit-border-radius: 0.75em !important;
    border-radius: 0.75em !important;
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    -o-transition: all .3s !important;
    -moz-transition: all .3s !important;
    -webkit-transition: all .3s !important;
    -ms-transition: all .3s !important;
    transition: all .3s !important;
}

.select {
    border: 0.5px solid @azul_1;
    text-align: left !important;
    color: @azul_1 !important;
    font-weight: 600 !important;
    font-family: MavenPro !important;
}

.opt {
    color: @azul_1 !important;
    font-weight: 600 !important;
    font-family: MavenPro !important;
}

button, input[type="submit"] {
    cursor: pointer;
}

.text-center {
    text-align: center;
}

form>h3 {
    text-align: center;
}

.botoes {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wrapper {
    padding-top: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "centerLeft centerRight";
}

.container_padrao {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    grid-area: centerLeft;
}

.logo>h3 {
    font-family: MavenPro;
    padding: .75rem;
    font-weight: 800;
    outline: 3px solid #fff;
    color: #fff;
}

.login {
    grid-area: centerRight;
}

.login>.formulario {
    width: 375px;
    margin: 0 3rem;
    height: 28.5em;
    margin-top: 0em;
}

.mini_punho {
    margin-top: 10px;
    width: 20%;
    transition: all 0.75s;
}

.formTitulo {
    font-family: 'MavenPro' !important;
    font-size: 1.1em;
    font-weight: bold;
    color: @azul_1;
}

@media (max-width: 768px) {
    .sisTitulo {
        margin-top: -1rem;
        display: block;
    }
    .wrapper {
        padding-top: 10%;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: minmax(20%, 80vmax);
        grid-template-areas: "centerLeft" "centerRight";
    }
    .login>form {
        width: 100%;
        margin: 0 3rem;
    }
    .logo {
        display: none;
    }
    .container_padrao {
        align-items: inherit;
    }
    .mini_punho {
        width: 35%;
    }
    .login>.formulario {
        height: 30em;
    }
}

.background {
    color: #999999;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -100;
    margin-bottom: 2.9rem;
}

/* -----------animação----------- */

// .ripple {
//     background-color: @azul_1;
//     width: 0.25rem;
//     height: 0.25rem;
//     border-radius: 50%;
//     position: relative;
//     animation: ripple 15s linear infinite;
//     animation-direction: alternate;
// }

// .ripple::before, .ripple::after {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     border-radius: 50%;
//     animation: inherit;
//     animation-delay: 2s;
// }

// .ripple::after {
//     animation-delay: 4s;
// }

// @keyframes ripple {
//     0% {
//         box-shadow: 0 0 0 0rem @verde_1;        
//     }
//     100% {        
//         box-shadow: 0 0 0 90rem #00969600
//     }
// }

.formButton {
    width: 300px;
    display: inline-flex;
}

.naoPossuoAcesso{
    color: #4e4ee5;
    cursor: pointer;
}



.btnCloseModal{
    font-size: 15px;
    display: flex;
    justify-content: end;
    cursor: pointer;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.logo_msgov {
    margin-left: 75px;
    height: 70px;
}
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;