.header {
    height: 3rem;
}

.navbar {
    height: 100% !important;
    padding: 1rem !important;
    margin-top: -5px;
    font-size: .765625rem;
    text-transform: uppercase;
    font-weight: 600;
}


.tituloSistema {
    text-align: center;
}

nav#customNav {
    color: white !important;
    background-color: #004F9F !important;
    position: relative !important;
    display: grid !important;
    grid-template-columns: 30% auto auto !important;
    align-content: center !important;
    justify-items: center;
    align-items: center;
}

.logo_msgov{
    height: 40px;
}
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;