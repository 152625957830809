:global {
    .tabcontent-component_content__S5bt7 {
        padding: 0;
    }

    .ant-form-vertical .ant-form-item-label,
    .ant-col-24.ant-form-item-label,
    .ant-col-xl-24.ant-form-item-label {
        padding-bottom: 1px !important;
    }

    @media (max-width:415px) {
        .ant-tabs-tab {
            margin-left    : 10px !important;
            margin-right   : 10px !important;
            justify-content: center !important;
        }

        .ant-tabs-tabpane {
            width: 20em !important;
        }


        .ant-tabs-content {
            display        : flex;
            justify-content: center;
            padding        : 5px;
        }

        .ant-form-item .ant-form-item-label>label {
            margin-top: 2px;
        }
    }

}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding: 15px;
    background-color: #c9c8c8;
}

.formulario {
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
}

.customSelect {
     :global {
        .ant-select-selection {
            width: 50px;
            background-color: olive;
        }
    }
}

.item {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
}

:host .atn-row {
    display: none;
}

.proximo {
    display: flex;
    height: 40px;
    color: white;
    font-size: 15px;
}

.salvar {
    display: flex;
    height: 40px;
    color: white;
    font-size: 15px;
}

.buttonsArea {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
}

.groupItem {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ant-row .ant-form-item {
    flex-flow: column;
    flex-direction: column;
}

.flex {
    display: flex;
}

.flex-direction {
    flex-direction: row;
}

.justifyContentSpaceBetween {
    justify-content: space-between;
}

.tab{
    width: 80%;
}
// .TabHeader:hover {
//     cursor: context-menu;
// }
@select-border-color: red;@select-dropdown-bg: blue;@input-disabled-color: black;